//
//
//
//
//
//
//
//

export default {
  name: 'ModalCloseButton',
  components: {},
  props: {
    wrapperClass: {
      type: String,
      default: '',
    },
    wrapperClassActive: {
      type: Boolean,
      default: false,
      required: false,
      validator(value) {
        return Boolean(value) === false || value === true
      },
    },
  },
}
