//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalCloseButton from '@/components/v2/span/ModalCloseButton.vue'
export default {
  name: 'DetailsInfo',
  components: { ModalCloseButton },
  props: {
    text: {
      type: String,
      default: '',
    },
    header: {
      type: String,
      default: '',
    },
    isVis: {
      type: Boolean,
      default: false,
    },
    btn: {
      type: String,
      default: '',
    },
  },

  data() {
    return {}
  },

  watch: {},

  methods: {
    closePopUp() {
      this.$emit('close')
    },
  },
}
